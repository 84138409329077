<template>
  <v-container>
    <v-row justify="center">
      <v-col md="12">
        <v-row>
          <v-col sm="4">
            <h1>Observation</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="3" sm="6">
            <BirdInfoPanel
              :fields="fields"
              @setLocation="setLocationManually"
            />
            <DateTimeInput :fields="fields" />
          </v-col>
          <v-col cols="12" lg="3" sm="6">
            <DataInputDialogRingAcrylic :data="fields.acrylic" />
            <DataInputDialogRingBto :data="fields.bto" />
            <v-divider></v-divider>
            <v-textarea
              class="mt-5"
              filled
              :label="fields.comments.text"
              v-model="fields.comments.vmodel"
            ></v-textarea>
          </v-col>
          <v-col cols="12" lg="3" sm="6">
            <p>Campsite</p>
            <v-radio-group v-model="fields.location.vmodel">
              <v-radio
                class="mb-6"
                v-for="(site, i) in campsiteLocations"
                :key="i"
                :label="site"
                :value="site"
              ></v-radio>
            </v-radio-group>
            <DataInputDialog
              :data="fields.other_location"
              v-if="fields.location.vmodel === 'Other'"
            />
          </v-col>
          <v-col cols="12" lg="3" sm="6">
            <p>Breeding status</p>
            <v-radio-group v-model="fields.breeder.vmodel">
              <v-radio
                class="mb-6"
                v-for="(status, i) in breedingStatus"
                :key="i"
                :label="status.label"
                :value="status.value"
              ></v-radio>
            </v-radio-group>
            <DataInputDialog
              :data="fields.breeder_chick_age"
              v-if="fields.breeder.vmodel === 'chick'"
            />
            <v-divider></v-divider>

            <v-checkbox
              :label="fields.gls_present.text"
              v-model="fields.gls_present.vmodel"
              hide-details
              class="shrink mr-2 mt-5 mb-2"
              color=""
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn x-large color="primary" @click="save">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localStorageUsername from '@/mixins/localstorageUsername.js'
import {
  Fields,
  pickWantedFields,
  convertFieldsToObject
} from '@/lib/Fields.js'
import Pouch from '@/store/Pouch.js'
import { EventBus } from '@/store/EventBus.js'
import DataInputDialog from '@/components/DataInputDialog.vue'
import DataInputDialogRingAcrylic from '@/components/DataInputDialogRingAcrylic.vue'
import DataInputDialogRingBto from '@/components/DataInputDialogRingBto.vue'
import DateTimeInput from '@/components/DateTimeInput.vue'
import BirdInfoPanel from '@/components/BirdInfoPanel.vue'

export default {
  name: 'Observe',
  props: {
    id: String
  },
  mixins: [localStorageUsername],
  data() {
    return {
      mode: 'new',
      edit: {
        _rev: null,
        _id: null,
        type: 'observe'
      },
      fields: Fields,
      birdInfo: null,
      snackbar: false,
      campsiteLocations: [
        'Main Colony East (MCE)',
        'Main Colony South (MCS)',
        'Above Campsite (AC)',
        'North Valley (NV)',
        'North Valley West (NVW)',
        'Other'
      ],
      breedingStatus: [
        { label: 'Not breeding', value: 'not breeding' },
        { label: 'Bird on an empty nest', value: 'bird on empty nest' },
        { label: 'Bird not on a nest', value: 'bird not on a nest' },
        { label: 'Egg', value: 'egg' },
        { label: 'Chick', value: 'chick' }
      ]
    }
  },
  beforeDestroy() {
    this.fields.reset()
  },
  mounted() {
    if (this.id) {
      this.mode = 'edit'
      this.findByIdAndMount(this.id)
    } else {
      this.getLocation()
    }
  },

  methods: {
    // fetch record by _id and populate fields
    async findByIdAndMount(id) {
      let res = await Pouch.get(id)
      this.edit._rev = res._rev
      this.edit._id = res._id
      for (const key of Object.keys(res)) {
        if (key in this.fields) {
          this.fields[key].vmodel = res[key]
        }
      }
    },
    setLocationManually() {
      // Grassholm location
      // 51.730841100569094, -5.479957028740447
      this.fields.lat.vmodel = 51.730841100569094
      this.fields.lng.vmodel = -5.479957028740447
    },
    getLocation() {
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0
      })
        .then(coordinates => {
          console.log(coordinates)
          this.fields.lat.vmodel = coordinates.lat
          this.fields.lng.vmodel = coordinates.lng
        })
        .catch(err => {
          console.log(err)
        })
    },

    save() {
      if (this.mode === 'new') {
        this.saveNew()
      } else if (this.mode === 'edit') {
        this.saveUpdate()
      }
    },
    async saveUpdate() {
      let doc = convertFieldsToObject(this.fields, 'observe')
      doc = { ...doc, ...this.edit }
      await Pouch.put(doc)
      EventBus.$emit('notify-user', 'Saved')
      this.fields.reset()
      this.$router.go(-1)
    },
    async saveNew() {
      let newDoc = {}
      newDoc.type = 'observe'
      Object.keys(this.fields).forEach(fieldname => {
        newDoc[fieldname] = this.fields[fieldname].vmodel
      })
      newDoc.scribe = this._getGlobalUsername()
      newDoc = pickWantedFields(newDoc, newDoc.type)
      await Pouch.post(newDoc)
      EventBus.$emit('notify-user', 'Saved')
      this.fields.reset()
    }
  },
  components: {
    DataInputDialog,
    DataInputDialogRingAcrylic,
    DataInputDialogRingBto,
    DateTimeInput,
    BirdInfoPanel
  }
}
</script>

<style lang="scss" scoped>
.white--text {
  color: #fff !important;
  font-size: 1rem;
}
</style>
